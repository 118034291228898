import { ErpTransferQuery } from "@/api/delivery/useErpTransferQuery";
import { GET_DELIVERY_QUERY_KEY } from "@/api/delivery/useGetDeliveryQuery";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import customAlert from "@/features/ui/alert/alert";
import { WmsLogsTable } from "@/features/wmsLogs/components/table";
import { useLoader } from "@/hooks/useLoader";

import { Button } from "@mantine/core";
import { WmsLogsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconArrowElbowRight } from "@tabler/icons-react";
import { useState } from "react";
import { useQueryClient } from "react-query";

const WmsLogs = Object.assign({}, Main, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    InventoryWrapper: Wrapper,
    ActionHeader: ActionHeader,
    ActionButton: Button,
    ActionButtonBox: ActionButtonBox,
    WmsLogsTable: WmsLogsTable,
});

export const WmsLogsPage = () => {

    const [isRowBtnActive, setIsRowBtnActive] = useState<boolean>(false);
    const [data, setData] = useState<WmsLogsGet200ResponseRowsInner[]>();
    const [id, setId] = useState<number[] | unknown>();
    const { setLoading, LoadingOverlay } = useLoader();
    const queryClient = useQueryClient();

    const handleRowSelect = (
        selectedRows: any,
        formatterPropsData: WmsLogsGet200ResponseRowsInner[],
        selectedRowsRef: any
    ) => {
        const isTransferable = formatterPropsData?.every((item) => (item?.actionType === "TRANSFER" || item?.actionType === "BATCH") && item?.checkErpStockMove === true);

        setIsRowBtnActive(isTransferable)

        // 여러 개의 row 데이터를 배열로 받으므로 이를 처리
        setData(formatterPropsData);  // 선택된 모든 row의 데이터를 배열로 저장
    
        if (selectedRowsRef.current) {
            const lastSet: number[] | unknown = Array.from(selectedRowsRef.current).slice(-1)[0];
            if (lastSet instanceof Set) {
                let idArray: number[] = [];
                lastSet.forEach((singleId) => {
                    idArray = idArray.concat(singleId);
                });
                setId(idArray);  // 선택된 row ID 배열을 업데이트
            }
        }
    }
    
    const handleTransfer = async () => {
        try {
            setLoading(true);
            const result = await ErpTransferQuery({
                erpSyncPushPushStockMovePostRequest: {
                    wmsLogIds: id as number[],
                },
            });

            const { data } = result;

            if (data) {
                const successItems = data.filter((item: any) => item.success === true);
                const failedItems = data.filter((item: any) => item.success === false);
                const successIdsString = successItems?.map((item: any) => item?.wmsLogIds)?.join(', ')

                const failedIdsString = failedItems?.map((item: any) => item?.wmsLogIds)?.join(', ')

                let message = '';
                if (successIdsString) {
                    message += `로그 번호 ${successIdsString} 번 전송이 완료되었습니다.\n`;
                }
                if (failedIdsString) {
                    message += `로그 번호 ${failedIdsString} 번 전송이 실패하였습니다.`;
                }

                customAlert(message, 'ERP 전송', successIdsString ? 'green' : 'red');
                queryClient.invalidateQueries(GET_DELIVERY_QUERY_KEY);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <WmsLogs.InventoryWrapper>
                <LoadingOverlay />
                <WmsLogs.ActionHeader>
                    <WmsLogs.ActionButtonBox>
                        <WmsLogs.ActionButton
                            color="violet"
                            rightIcon={<IconArrowElbowRight />}
                            disabled={!isRowBtnActive}
                            onClick={handleTransfer}
                        >
                            재고 이동 연동
                        </WmsLogs.ActionButton>
                    </WmsLogs.ActionButtonBox>
                </WmsLogs.ActionHeader>
                <WmsLogs.Header>
                    <WmsLogs.HeaderTitle>WMS작업 이력 조회</WmsLogs.HeaderTitle>
                    <WmsLogs.HeaderSubTitle>
                        우리회사에서 다루는 wms작업 이력을 조회합니다.
                    </WmsLogs.HeaderSubTitle>
                </WmsLogs.Header>
                <WmsLogs.WmsLogsTable onRowSelect={handleRowSelect} />
            </WmsLogs.InventoryWrapper>
        </>
    );
};