import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { validateFormValues } from "@/utils/validation";
import { Button, Checkbox, Flex, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

const Create = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Checkbox,
    Select: FormSelect,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    Text: Text,
    Flex: Flex,
});

export const DepartmentCreateForm = () => {
    const { closeModal } = useModal();

    const form = useForm({
        initialValues: {
            departmentCode: undefined,
            departmentName: undefined,
        }
    })

    const onSubmit = async () => {
        try {
            closeModal(form.values);

            customAlert("부서 등록에 실패하였습니다.", '등록 실패', 'red')
        } catch (e) {
            customAlert(
                "부서 등록에 실패하였습니다.",
                "등록 실패",
                "red"
            )
        }
    };

    return (
        <Create.Wrapper>
            <Create.Input
                withAsterisk
                label="부서 코드"
                placeholder="부서 코드"
                {...form.getInputProps("departmentCode")}
            />
            <Create.Input
                withAsterisk
                label="부서명"
                placeholder="부서명"
                {...form.getInputProps("departmentName")}
            />
            <Create.ButtonBox>
                <Create.Button color="gray" onClick={closeModal}>취소</Create.Button>
                <Create.Button
                    disabled={validateFormValues({
                        pageType: "department",
                        values: form.values,
                    })}
                    onClick={onSubmit}
                >
                    부서 생성
                </Create.Button>
            </Create.ButtonBox>
        </Create.Wrapper>
    )
};