import { mutateLocations } from "@/api/locations/useLocationsQuery";
import styled from "@emotion/styled";
import { Button, Flex, Select, SelectProps, Text } from "@mantine/core";
import { MasterApiLocationsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconX } from "@tabler/icons-react";
import { EventHandler, ReactNode, forwardRef, useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useModal } from "../../../modal/ModalStackManager";
import { LocationForm } from "../../form/locations/form";
import {
  LocationOption,
  useLocationsAutoComplate,
  useLocationsCode,
} from "./auto-useGetLocationsQuery";

interface LocationProps extends React.ComponentPropsWithoutRef<"div"> {
  // group: string;
  label: string;
  value: string;
}

export interface LocationAutoCompleteProps
  extends Partial<SelectProps>,
  Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (locationCode: string | null) => void;
  maxDropdownHeight?: number;
  outCode?: string | null;
  itemCodes?: string | null | string[];
  isAvailable?: boolean
  width?: string;
}

export const LocationAutoComplete = (params: LocationAutoCompleteProps) => {
  const {
    value: locationCode,
    onChange,
    maxDropdownHeight,
    width,
    outCode,
    itemCodes,
    data,
    isAvailable,
    ...etcParams
  } = params;

  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  const query = {
    $and: [
      {
        itemCode: itemCodes ? Array.isArray(itemCodes) ? itemCodes : [itemCodes] : undefined
      },
      {
        isAvailable: isAvailable
      }
    ],
  };

  const { data: options } = useLocationsAutoComplate(focused, query, searchKeyword);


  const { data: initialOptions } = useLocationsCode(
    !!locationCode,
    locationCode ?? null
  );

  const selectRef = useRef<HTMLInputElement>(null);  // Select 컴포넌트를 참조하기 위한 ref

  const [localOptions, setLocalOptions] = useState<LocationOption[]>([]);

  useEffect(() => {
    if (options) {
      setLocalOptions(options);
    }
  }, [options]);

  let selectedLocation = initialOptions?.find((item) => {
    return item.value === locationCode;
  });

  const onChangeHandler = (e: string | null) => {
    selectedLocation = options?.find((item) => item.value === e);
    onChange && onChange(e);
  };

  const SelectItem = forwardRef<HTMLDivElement, LocationProps>(
    ({ label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <div>
            <Text>{name}</Text>
            <Text fz="xs"> (code: {code})</Text>
          </div>
        </Flex>
      </div>
    )
  );

  const handleKeyDown = (e: { key: string; }) => {
    if (e.key === 'Enter') {
      const matchingOption = localOptions?.find((item) => item.value === searchKeyword);
      if (matchingOption) {
        onChangeHandler(matchingOption.value);
        selectRef.current?.blur();  // Select 컴포넌트의 포커스를 잃음
      }
    }
  };

  return (
    <LocationSelect
      ref={selectRef}  // Select 컴포넌트에 ref 연결
      styles={
        locationCode
          ? {
            input: {
              width: width,
              fontSize: "0.8em",
              paddingBottom: "0.5rem",
            },
          }
          : { input: { width: width } }
      }
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      inputContainer={(children: ReactNode) => (
        <div style={{ position: "relative" }}>
          {children}
          {selectedLocation?.value && (
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                fontSize: "0.8em",
                color: "#888",
                left: "5px",
              }}
            >
              {"Code: " + selectedLocation?.value}
            </div>
          )}
        </div>
      )}
      value={locationCode && locationCode}
      itemComponent={SelectItem}
      searchValue={searchKeyword}
      data={data ?? [...(options ?? []), ...(initialOptions ?? [])].reduce(
        (unique: LocationProps[], option: LocationProps) => {
          return unique.some((u) => u.value === option.value) ||
            option.value === outCode
            ? unique
            : [...unique, option];
        },
        []
      )}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={LocationInfo({
        location: selectedLocation as LocationOption,
        onChange: onChangeHandler,
      })}
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.value?.toLowerCase().includes(value.toLowerCase().trim())
      }
      nothingFound={AddNewLocation({
        locationName: searchKeyword,
        onChange: onChangeHandler,
      })}
      {...etcParams}
      onKeyDown={handleKeyDown}
    />
  );
};

const LocationInfo = (params: {
  location?: LocationOption;
  onChange: (locationCode: string | null) => void;
}) => {
  const { location, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return location?.value ? (
    <LocationInfoLabel>
      <IconX size="1rem" onClick={clearHandler} />{" "}
    </LocationInfoLabel>
  ) : null;
};

const AddNewLocation = (params: {
  locationName: string;
  onChange: (locationCode: string) => void;
}) => {
  const { locationName, onChange } = params;
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (params: MasterApiLocationsPostRequest) =>
      mutateLocations.create(params).mutationFn(params as MasterApiLocationsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('locations')
      }
    }
  );

  const onCloseHandler: EventHandler<any> = (values) => {
    mutate({
      locationsGetRequest: {
        code: values.code!,
        name: values.name!,
        siteCode: values.siteCode!,
      },
    });
    closeModal(values);
  };
  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    openModal(
      <LocationForm name={locationName} onSubmit={onCloseHandler} />,
      null,
      "신규 창고 등록",
      true
    ).then((result) => {
      onChange(result?.code);
    });
  };

  return (
    <Button variant="subtle" onClick={addNewModalHandler}>
      신규 창고({locationName}) 등록
    </Button>
  );
};
const LocationInfoLabel = styled.div`
  font-size: 12px;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  display: flex;
  align-items: center;
  position: absolute;
`;

const LocationSelect = styled(Select)`
`;
