import DefaultInstance from "@/instance/axios";
import { createMutationKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { DefaultApiRoutingBomsRoutingBomIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";



const ROUTING_BOMS_KEY = 'routingBoms';

export const mutateRoutingBoms = createMutationKeys(ROUTING_BOMS_KEY, {
    update: (params: DefaultApiRoutingBomsRoutingBomIdPutRequest) => ({
        mutationKey: [params.routingBomId, params.routingBomsGetRequest],
        mutationFn: () => DefaultInstance.routingBomsRoutingBomIdPut(params)
    }),
});

export const routingsQueryKeys = mergeQueryKeys(mutateRoutingBoms);