import { mutatePurchaseOrderItems } from "@/api/purchaseOrderItems/usePurchaseOrderItems";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { PartnerAutoComplete } from "@/features/ui/autoComplete/partner/partner-autoComplete";
import { PurchaseOrderAutoComplete } from "@/features/ui/autoComplete/purchaseOrder/purchaseOrders-autoComplete";
import { UserAutoComplete } from "@/features/ui/autoComplete/user/user-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { ScmApiPurchaseOrderItemsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "react-query";

const Create = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Select: FormSelect,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    Text: Text,
    Flex: Flex,
    DateInput: DateInput
});

interface OrderItemCreateFormProps {
    orderId?: number;
}

export const OrderItemsCreateForm = (
    props: OrderItemCreateFormProps
) => {
    const { orderId } = props;
    const { closeModal } = useModal();
    const queryClient = useQueryClient();
    const { mutate: createMutate } = useMutation(
        (params: ScmApiPurchaseOrderItemsPostRequest) =>
            mutatePurchaseOrderItems.create(params).mutationFn(params as ScmApiPurchaseOrderItemsPostRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('purchaseOrderItems');
            }
        }
    );

    const form = useForm({
        initialValues: {
            purchaseOrderId: undefined,
            partnerCode: undefined,
            itemCode: undefined,
            quantity: undefined,
            orderDate: undefined,
            deliveryDeadline: undefined,
            userCode: undefined,
            targetLocationCode: undefined,
        }
    })

    const onSubmit = async () => {
        try {
            createMutate(
                {
                    purchaseOrderItemsGetRequest: {
                        purchaseOrderId: orderId ? orderId : form.values.purchaseOrderId!,
                        partnerCode: form.values.partnerCode!,
                        direction: "OUTBOUND",
                        itemCode: form.values.itemCode!,
                        quantity: form.values.quantity!,
                        orderDate: form.values.orderDate!,
                        deliveryDeadline: form.values.deliveryDeadline!,
                        userCode: form.values.userCode!,
                        targetLocationCode: form.values.targetLocationCode!,
                    }
                },
                {
                    onSuccess: (res) => {
                        closeModal(form.values);
                        if (res.status === 200) {
                            customAlert(
                                "발주 항목 등록에 성공하였습니다.",
                                "등록 성공",
                                "green"
                            )
                        }
                    },
                    onError: (error: any) => {
                        customAlert(handleErrorResponse({ error, errorContext: '발주 항목 ' }), '발주 항목  등록 실패', 'red')
                    }
                }
            )
        } catch (e) {
            customAlert(
                "발주 항목  등록에 실패하였습니다.",
                "등록 실패",
                "red"
            )
        }
    };

    return (
        <>
            <Create.Wrapper>
                {orderId ? (
                    null
                ) : (
                    <PurchaseOrderAutoComplete
                        direction="OUTBOUND"
                        label="발주 코드"
                        placeholder="발주 코드 입력"
                        {...form.getInputProps("purchaseOrderId")}
                    />
                )}
                <PartnerAutoComplete
                    label="협력사"
                    placeholder="협력사"
                    {...form.getInputProps("partnerCode")}
                    withAsterisk
                />
                <ItemAutoComplete
                    label="품목 코드"
                    placeholder="품목 코드 입력"
                    {...form.getInputProps("itemCode")}
                />
                <Create.Input
                    label="수량"
                    placeholder="수량"
                    {...form.getInputProps("quantity")}
                />
                <UserAutoComplete
                    label="담당자"
                    placeholder="담당자 입력"
                    {...form.getInputProps("userCode")}
                />
                <LocationAutoComplete
                    label="출고 로케이션"
                    placeholder="출고 로케이션"
                    {...form.getInputProps("targetLocationCode")}
                />
                <Create.DateInput
                    popoverProps={{ withinPortal: true }}
                    icon={<IconCalendar size="1.2rem" />}
                    label="발주일"
                    valueFormat="YYYY-MM-DD"
                    {...form.getInputProps("orderDate")}
                />
                <Create.DateInput
                    popoverProps={{ withinPortal: true }}
                    icon={<IconCalendar size="1.2rem" />}
                    label="납기일"
                    valueFormat="YYYY-MM-DD"
                    {...form.getInputProps("deliveryDeadline")}
                />
                <Create.ButtonBox>
                    <Create.Button color="gray" onClick={closeModal}>취소</Create.Button>
                    <Create.Button
                        disabled={validateFormValues({
                            pageType: "purchaseOrderItems",
                            values: form.values,
                        })}
                        onClick={onSubmit}
                    >
                        발주 항목 생성
                    </Create.Button>
                </Create.ButtonBox>
            </Create.Wrapper>
        </>
    )
};