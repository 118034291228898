import { Box, Button, Code, Select, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";

export function PartnerForm(params: {
    code: string;
    onSubmit?: (values: any) => void;
}) {
    const { t } = useTranslation();

    const { code, onSubmit } = params;
    const form = useForm({
        initialValues: {
            code: code,
            name: undefined,
            type: undefined,
            contact: undefined,
        },
    });

    return (
        <Box maw={320} mx="auto" w={400}>
            <form onSubmit={form.onSubmit((values) => onSubmit && onSubmit(values))}>
                <TextInput label="협력사 코드" {...form.getInputProps("code")} />
                <TextInput label="협력사 명" {...form.getInputProps("name")} />
                <Select
                    label="협력사 타입"
                    data={[
                        { value: "SALES", label: "판매" },
                        { value: "PURCHASE", label: "구매" },
                        { value: "BI_DIRECTION", label: "양방향" },
                    ]}
                    {...form.getInputProps("type")}
                />
                <TextInput
                    label="연락처"
                    {...form.getInputProps("contact")}
                />
                <Text size="sm" weight={500} mt="xl">
                    Form values:
                </Text>
                <Code block mt={5}>
                    {JSON.stringify(form.values, null, 2)}
                </Code>
                <Button
                    type="submit"
                    disabled={
                        !form.values.code ||
                        !form.values.name ||
                        !form.values.type ||
                        !form.values.contact
                    }
                >
                    등록
                </Button>
            </form>
        </Box>
    );
}
