import { mutateItems } from "@/api/items/useItemQuery";
import { itemType } from "@/constants/items";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { AbstractItemAutoComplete } from "@/features/ui/autoComplete/abstractItem/abstractItem-autoComplete";
import { ItemCategoryAutoComplete } from "@/features/ui/autoComplete/itemCategory/itemCategory-autoComplete";
import styled from "@emotion/styled";
import {
  Button,
  Checkbox,
  Group,
  Input,
  NumberInput,
  Select,
  Space,
  Text,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiItemsItemIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

export const ItemsUpdateForm = (params: { formatterProps: any }) => {
  const { t } = useTranslation();
  const lnItemType = itemType.map((item: any) => ({
    ...item,
    label: t(item.label),
  }));

  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const { mutate: updateMutate } = useMutation(
    (params: MasterApiItemsItemIdPutRequest) =>
      mutateItems.update(params).mutationFn(params as MasterApiItemsItemIdPutRequest | any),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("items");
      }
    }
  );

  const formData = useForm({
    initialValues: {
      code: formatterProps.code,
      name: formatterProps.name,
      itemType: formatterProps.itemType,
      itemCategoryName: formatterProps.itemCategoryName,
      abstractItemCode: formatterProps.abstractItemCode,
      spec: formatterProps.spec,
      width: Number(formatterProps.width) ?? undefined,
      height: Number(formatterProps.height) ?? undefined,
      depth: Number(formatterProps.depth) ?? undefined,
      texture: formatterProps.texture,
      standardUph: formatterProps.standardUph,
      unitText: formatterProps.unitText,
      usesLotName: formatterProps.usesLotName,
      usesLotExpiration: formatterProps.usesLotExpiration,
    },
  });

  const onSubmit = async () => {
    try {
      updateMutate(
        {
          itemId: params.formatterProps.id,
          itemsGetRequest: {
            name: formData.values.name as any,
            code: formData.values.code as any,
            itemType: formData.values.itemType as any,
            itemCategoryName: formData.values.itemCategoryName,
            abstractItemCode: formData.values.abstractItemCode,
            spec: formData.values.spec,
            width: formData.values.width.toString(),
            height: formData.values.height.toString(),
            depth: formData.values.depth.toString(),
            texture: formData.values.texture,
            standardUph: formData.values.standardUph,
            unitText: formData.values.unitText,
            usesLotName: formData.values.usesLotName,
            usesLotExpiration: formData.values.usesLotExpiration,
          },
        },
        {
          onSuccess: (res) => {
            closeModal(formatterProps);
            if (res.status === 200) {
              customAlert(
                "품목 수정에 성공하였습니다.",
                "수정 성공",
                "green"
              )
            } else {
              customAlert(
                "품목 수정에 실패하였습니다.",
                "수정 실패",
                "red"
              )
            }
          },
        } as const
      );
    } catch (e) {
      customAlert(
        "품목 수정에 실패하였습니다.",
        "수정 실패",
        "red"
      )
    }
  };

  return (
    <ItemInfoWrapper>
      <ItemTitle>
        <InputField>
          <Title order={2}>*품명</Title>
          <Input
            placeholder="품목명"
            size="lg"
            {...formData.getInputProps("name")}
            styles={{
              input: {
                color: formData.isDirty("name")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: formData.isDirty("name")
                  ? "cadetblue !important"
                  : "inherit",
              },
            }}
          />
        </InputField>
      </ItemTitle>
      <Spacing />
      <Field>
        <LabelText fw={700} c="dimmed">
          *타입
        </LabelText>
        <InputContent>
          <SelectInput
            placeholder="품목유형을 선택해 주세요."
            data={lnItemType}
            {...formData.getInputProps("itemType")}
            styles={{
              input: {
                color: formData.isDirty("itemType")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: formData.isDirty("itemType")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          *코드
        </LabelText>
        <InputContent>
          <InputText
            placeholder="코드를 입력해주세요."
            {...formData.getInputProps("code")}
            styles={{
              input: {
                color: formData.isDirty("code")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: formData.isDirty("code")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          분류
        </LabelText>
        <InputContent>
          <ItemCategoryAutoComplete
            placeholder="품목분류를 선택해 주세요."
            {...formData.getInputProps("itemCategoryName")}
            styles={{
              input: {
                color: formData.isDirty("itemCategoryName")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: formData.isDirty("itemCategoryName")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          품목군
        </LabelText>
        <InputContent>
          <AbstractItemAutoComplete
            placeholder="품목군을 선택해 주세요."
            {...formData.getInputProps("abstractItemCode")}
            styles={{
              input: {
                color: formData.isDirty("abstractItemCode")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: formData.isDirty("abstractItemCode")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          규격
        </LabelText>
        <InputContent>
          <InputText
            placeholder="규격을 입력해주세요."
            {...formData.getInputProps("spec")}
            styles={{
              input: {
                color: formData.isDirty("spec")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: formData.isDirty("spec")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          높이
        </LabelText>
        <InputContent>
          <NumberInput
            min={1}
            placeholder="높이를 입력해주세요."
            step={0.01}
            precision={2}
            {...formData.getInputProps("height")}
            styles={{
              input: {
                color: formData.isDirty("height")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: formData.isDirty("height")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          깊이
        </LabelText>
        <InputContent>
          <NumberInput
            min={1}
            placeholder="깊이를 입력해주세요."
            {...formData.getInputProps("depth")}
            step={0.01}
            precision={2}
            styles={{
              input: {
                color: formData.isDirty("depth")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: formData.isDirty("depth")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          폭
        </LabelText>
        <InputContent>
          <NumberInput
            min={1}
            placeholder="폭 을 입력해주세요."
            step={0.01}
            precision={2}
            {...formData.getInputProps("width")}
            styles={{
              input: {
                color: formData.isDirty("width")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: formData.isDirty("width")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          재질
        </LabelText>
        <InputContent>
          <InputText
            placeholder="재질을 입력해주세요."
            {...formData.getInputProps("texture")}
            styles={{
              input: {
                color: formData.isDirty("texture")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: formData.isDirty("texture")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          기준UPH
        </LabelText>
        <InputContent>
          <InputText
            placeholder="기준UPH를 입력해주세요."
            {...formData.getInputProps("standardUph")}
            styles={{
              input: {
                color: formData.isDirty("standardUph")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: formData.isDirty("standardUph")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          단위
        </LabelText>
        <InputContent>
          <InputText
            placeholder="단위를 입력해주세요."
            {...formData.getInputProps("unitText")}
            styles={{
              input: {
                color: formData.isDirty("unitText")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: formData.isDirty("unitText")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          LOT 관리 기준
        </LabelText>
        <Group>
          <Checkbox
            label="이름"
            {...formData.getInputProps("usesLotName")}
            checked={formData.values.usesLotName}
            styles={{
              input: {
                color: formData.isDirty("usesLotName")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: formData.isDirty("usesLotName")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
          <Checkbox
            label="유효기한"
            {...formData.getInputProps("usesLotExpiration")}
            checked={formData.values.usesLotExpiration}
            styles={{
              input: {
                color: formData.isDirty("usesLotExpiration")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: formData.isDirty("usesLotExpiration")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
        </Group>
      </Field>
      <Spacing />
      <ModalConfirmBtnBox>
        <Button color="gray" onClick={closeModal}>
          취소
        </Button>
        <Button onClick={onSubmit}>품목 수정</Button>
      </ModalConfirmBtnBox>
    </ItemInfoWrapper>
  );
};

const ItemInfoWrapper = styled.div`
  display: flex;
  padding: 16px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const ItemTitle = styled.div`
  display: flex;
  height: 108px;
  align-items: flex-start;
  align-self: stretch;
`;

const Spacing = styled(Space)`
  display: flex;
  height: 16px;
  flex-direction: column;
  align-items: flex-start;
`;

const InputField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  flex: 1 0 0;
`;

const InputContent = styled.div`
  display: flex;
  padding: 2px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
`;

const LabelText = styled<any>(Text)`
  display: flex;
  width: 120px;
  padding: 2px 0px;
  flex-direction: column;
  align-items: flex-start;
`;

const InputText = styled<any>(Input)`
  display: flex;
  width: 410px;

  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
`;

const SelectInput = styled<any>(Select)`
  display: flex;
  width: 200px;

  align-items: flex-start;
`;

const Field = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 23px;
  align-self: stretch;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
