import { IncomingForm } from "@/features/inventory/components/Form/IncomingForm";
import { InventoryTable } from "@/features/inventory/components/table";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { ActionIcon, Button, Flex, Title } from "@mantine/core";

import { OutgoingForm } from "@/features/inventory/components/Form/OutgoingForm";
import { TransferForm } from "@/features/inventory/components/Form/TransferForm";
import { WorkInputForm } from "@/features/inventory/components/Form/WorkInputForm";
import { useModal } from "@/features/modal/ModalStackManager";

import { CloseForm } from "@/features/inventory/components/Form/CloseForm";
import { DeferredLotInfoForm } from "@/features/inventory/components/Form/DeferredLotInfoForm";
import { DiligenceForm } from "@/features/inventory/components/Form/DiligenceForm";
import {
  IconArrowBarToRight,
  IconCalendar,
  IconCalendarOff,
  IconEdit,
  IconFileSpreadsheet,
  IconMinus,
  IconPlus,
  IconPrinter,
  IconWritingSign,
} from "@tabler/icons-react";

import { users } from "@/api/users/useUsersQuery";
import { DiligenceAllForm } from "@/features/inventory/components/Form/DiligenceAllForm";
import { IncomingAllForm } from "@/features/inventory/components/Form/IncomingAllForm";
import { OutgoingAllForm } from "@/features/inventory/components/Form/OutgoingAllForm";
import { TransferAllForm } from "@/features/inventory/components/Form/TransferAllForm";
import { Calendar, CalendarDate, isCalendarDate } from "@/features/ui/Calendar";
import { usePub } from "@/hooks";
import { getPrinterCodeByUserWithWhoami } from "@/utils/checkData";
import { ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models";
import axios from "axios";
import dayjs from "dayjs";
import { useState } from "react";
import { useQuery } from "react-query";

const Inventory = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  InventoryWrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  ActionButtonBox: ActionButtonBox,
  IncomingForm: IncomingForm,
  IncomingAllForm: IncomingAllForm,
  OutgoingForm: OutgoingForm,
  OutgoingAllForm: OutgoingAllForm,
  TransferForm: TransferForm,
  TransferAllForm: TransferAllForm,
  WorkInputForm: WorkInputForm,
  DiligenceForm: DiligenceForm,
  DiligenceAllForm: DiligenceAllForm,
  CloseForm: CloseForm,
  DeferredLotInfoForm: DeferredLotInfoForm,
});

export const InventoryPage = () => {
  const { openModal } = useModal();
  const publish = usePub();

  const [isRowBtnActive, setIsRowBtnActive] = useState<boolean>(false);
  const [selectedRowsInform, setSelectedRowsInform] = useState<ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[]>([]);
  const [data, setData] = useState<any>([]);
  const [datas, setDatas] = useState<any>([]);
  const [dateRange, setDateRange] = useState<CalendarDate>([dayjs().startOf('month').toDate(), dayjs().endOf('month').toDate()]);
  const { data: userData, refetch } = useQuery(users.who({}))

  let isPrintBarcode = selectedRowsInform.length !== 0

  // 1개의 row만 선택하면 활성화되도록
  const handleRowSelect = (
    selectedRows: any,
    formatterPropsData: any,
    selectedRowsRef: any,
    selectedRowsInform: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[]
  ) => {
    setIsRowBtnActive(selectedRows.size === 1);
    setData(formatterPropsData);
    setSelectedRowsInform(selectedRowsInform);
  };

  const printBarcode = async () => {

    const hasLotName = selectedRowsInform.map((row) => row.lot?.name).every((name) => name !== null)

    if (window.confirm("바코드를 출력하시겠습니까?")) {
      if (!hasLotName) {
        alert("바코드를 출력할 수 없습니다. 로트명이 없는 재고가 포함되어 있습니다.")
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_COMPANY_BARCODE_ADDRESS_INVENTORY}`, {
          company_code: process.env.REACT_APP_COMPANY_CODE,
          lot_name: selectedRowsInform.map((row) => row.lot?.name),
          printer_code: await getPrinterCodeByUserWithWhoami(),
          quantity: 1, // 몇 장 뽑을 것인지에 대한 quantity 정보
          location_code: selectedRowsInform.map((row) => row.locationCode),
          item_code: selectedRowsInform.map((row) => row.itemCode),
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        console.log(response.data);
      } catch (error) {
        console.error('Error:', error);
      }

      // fetch('address', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     "company_code": "7KH99D", // TODO: 환경변수로 관리?
      //     "lot_name": selectedRowsInform.map((row) => row.lot?.name),
      //     "printer_code": await getPrinterCodeByUserWithWhoami(),
      //     "quantity": 1, // 몇장 뽑을 것인지에 대한 quantity 정보
      //     "location_code": selectedRowsInform.map((row) => row.locationCode),
      //     "item_code": selectedRowsInform.map((row) => row.itemCode),
      //   })
      // }).then(response => response.json()
      // ).then(data => console.log(data)
      // ).catch((error) => {
      //   console.error('Error:', error);
      // });
    }
  }

  return (
    <>
      <Inventory.InventoryWrapper>
        <Inventory.ActionHeader>
          <ActionButtonBox>
            <Inventory.ActionButton
              leftIcon={<IconPlus />}
              onClick={() =>
                openModal(<Inventory.IncomingForm />, null, "재고 입고", true)
              }
            >
              입고
            </Inventory.ActionButton>
            <Inventory.ActionButton
              leftIcon={<IconPlus />}
              color="indigo"
              onClick={() =>
                openModal(<Inventory.IncomingAllForm />, null, "재고 일괄 입고", true)}>
              일괄 입고
            </Inventory.ActionButton>
            <Inventory.ActionButton
              leftIcon={<IconMinus />}
              color="red"
              onClick={() => {
                if (isRowBtnActive) {
                  openModal(
                    <Inventory.OutgoingForm formatterProps={data} />,
                    null,
                    "재고 출고",
                    true
                  );
                }
              }}
              disabled={!isRowBtnActive}
            >
              출고
            </Inventory.ActionButton>
            <Inventory.ActionButton
              leftIcon={<IconMinus />}
              color="pink"
              onClick={() => {
                refetch();
                openModal(
                  <Inventory.OutgoingAllForm userData={userData} />,
                  null,
                  "재고 벌크 출고",
                  true
                );
              }}
            >
              벌크 출고
            </Inventory.ActionButton>
            <Inventory.ActionButton
              leftIcon={<IconPrinter />}
              color="orange"
              onClick={printBarcode}
              disabled={!isPrintBarcode}
            >
              바코드 출력
            </Inventory.ActionButton>
          </ActionButtonBox>
          <ActionButtonBox>
            <Inventory.ActionButton
              rightIcon={<IconFileSpreadsheet />}
              color="teal"
              onClick={() => {
                publish("excelDownLoad");
              }}
            >
              엑셀 다운로드
            </Inventory.ActionButton>
            <Inventory.ActionButton
              rightIcon={<IconWritingSign />}
              color="green"
              onClick={() => {
                if (data?.isUnknown === true) {
                  openModal(
                    <Inventory.DeferredLotInfoForm formatterProps={data} />,
                    null,
                    "재고 정보 재입력",
                    true
                  );
                }
              }}
              disabled={!data?.isUnknown === true}
            >
              재고 정보 재입력
            </Inventory.ActionButton>
            <Inventory.ActionButton
              rightIcon={<IconCalendarOff />}
              color="indigo"
              onClick={() => {
                if (isRowBtnActive) {
                  openModal(
                    <Inventory.CloseForm formatterProps={data} />,
                    null,
                    "재고 마감",
                    true
                  );
                }
              }}
              disabled={!isRowBtnActive}
            >
              마감
            </Inventory.ActionButton>
            <Inventory.ActionButton
              rightIcon={<IconEdit />}
              color="yellow"
              onClick={() => {
                if (isRowBtnActive) {
                  openModal(
                    <Inventory.DiligenceForm formatterProps={data} />,
                    null,
                    "재고 조정",
                    true
                  );
                }
              }}
              disabled={!isRowBtnActive}
            >
              조정
            </Inventory.ActionButton>
            <Inventory.ActionButton
              rightIcon={<IconEdit />}
              color="lime"
              onClick={() => {
                if (selectedRowsInform.length >= 2) {
                  openModal(
                    <Inventory.DiligenceAllForm formatterProps={selectedRowsInform} />,
                    null,
                    "재고 일괄 조정",
                    true
                  )
                }
              }}
              disabled={selectedRowsInform.length < 2}
            >
              일괄 조정
            </Inventory.ActionButton>
            <Inventory.ActionButton
              color="red"
              rightIcon={<IconArrowBarToRight />}
              onClick={() =>
                openModal(
                  <Inventory.TransferForm formatterProps={data} />,
                  null,
                  "재고 이동",
                  true
                )
              }
              disabled={!isRowBtnActive}
            >
              이동
            </Inventory.ActionButton>

            <Inventory.ActionButton
              color="pink"
              rightIcon={<IconArrowBarToRight />}
              onClick={() => {
                refetch();
                openModal(
                  <Inventory.TransferAllForm userData={userData} />,
                  null,
                  "재고 벌크 이동",
                  true
                )
              }}
            >
              벌크 이동
            </Inventory.ActionButton>
          </ActionButtonBox>
        </Inventory.ActionHeader>
        <Flex w="100%" p="xs" justify="center" gap="xs" align="center">
          <Title size="h1" weight={700}>
            {dayjs(dateRange[0]).format("YYYY-MM-DD")} ~ {dayjs(dateRange[1]).format("YYYY-MM-DD")}
          </Title>
          <ActionIcon onClick={() => openModal(<Calendar dates={dateRange} type="range" />, null, "날짜 선택").then((result) => { if (isCalendarDate(result)) { setDateRange(result) } })}>
            <IconCalendar size="2rem" />
          </ActionIcon>
        </Flex>
        <Inventory.Header>
          <Inventory.HeaderTitle>재고</Inventory.HeaderTitle>
          <Inventory.HeaderSubTitle>
            우리 회사에서 다루는 재고를 관리합니다.
          </Inventory.HeaderSubTitle>
        </Inventory.Header>
        <InventoryTable onRowSelect={handleRowSelect} dates={[dayjs(dateRange[0]).startOf('day').toDate(), dayjs(dateRange[1]).endOf('day').toDate()]} />
      </Inventory.InventoryWrapper>
    </>
  );
};
