import { mutatePurchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { PartnerAutoComplete } from "@/features/ui/autoComplete/partner/partner-autoComplete";
import { UserAutoComplete } from "@/features/ui/autoComplete/user/user-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { ScmApiPurchaseOrdersPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "react-query";

const Create = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Select: FormSelect,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    Text: Text,
    Flex: Flex,
    DateInput: DateInput
});

export const PurchaseOrderCreateForm = () => {
    const { closeModal } = useModal();
    const queryClient = useQueryClient();
    const { mutate: createMutate } = useMutation(
        (params: ScmApiPurchaseOrdersPostRequest) =>
            mutatePurchaseOrders.create(params).mutationFn(params as ScmApiPurchaseOrdersPostRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('purchaseOrders');
            }
        }
    );

    const form = useForm({
        initialValues: {
            partnerCode : undefined,
            // status : undefined,
            code : undefined,
            orderDate : undefined,
            deliveryDeadline : undefined,
            userCode : undefined,
            sourceLocationCode : undefined,
        }
    })

    const onSubmit = async () => {
        try {
            createMutate(
                {
                    purchaseOrdersGetRequest : {
                        partnerCode : form.values.partnerCode!,
                        direction : "INBOUND",
                        code : form.values.code!,
                        // status : form.values.status!,
                        orderDate : form.values.orderDate!,
                        deliveryDeadline : form.values.deliveryDeadline!,
                        userCode : form.values.userCode!,
                        sourceLocationCode : form.values.sourceLocationCode!,
                    }
                },
                {
                    onSuccess: (res) => {
                        closeModal(form.values);
                        if (res.status === 200) {
                            customAlert(
                                "수주 등록에 성공하였습니다.",
                                "등록 성공",
                                "green"
                            )
                        }
                    },
                    onError: (error: any) => {
                        customAlert(handleErrorResponse({ error, errorContext: '수주' }), '수주 등록 실패', 'red')
                    }
                }
            )
        } catch (e) {
            customAlert(
                "수주 등록에 실패하였습니다.",
                "등록 실패",
                "red"
            )
        }
    };

    return (
        <>
            <Create.Wrapper>
                <PartnerAutoComplete
                    label="협력사"
                    placeholder="협력사"
                    {...form.getInputProps("partnerCode")}
                    withAsterisk
                />
                <Create.Input
                    withAsterisk
                    label="수주 코드"
                    placeholder="수주 코드 입력"
                    {...form.getInputProps("code")}
                />
                {/* <Select
                    label="수주상태"
                    placeholder="수주상태"
                    data={[
                        { value: "DRAFT", label: "초안" },
                        { value: "IN_PROGRESS", label: "진행중" },
                        { value: "DONE", label: "완료" },
                        { value: "CANCELED", label: "취소됨" },
                    ]}
                    {...form.getInputProps("status")}
                /> */}
                <UserAutoComplete 
                    label="담당자"
                    placeholder="담당자 입력"
                    {...form.getInputProps("userCode")}
                />
                <LocationAutoComplete 
                    label="출고 로케이션"
                    placeholder="출고 로케이션"
                    {...form.getInputProps("sourceLocationCode")}
                />
                <Create.DateInput
                    popoverProps={{ withinPortal: true }}
                    icon={<IconCalendar size="1.2rem" />}
                    label="수주일"
                    valueFormat="YYYY-MM-DD"
                    {...form.getInputProps("orderDate")}
                />
                <Create.DateInput
                    popoverProps={{ withinPortal: true }}
                    icon={<IconCalendar size="1.2rem" />}
                    label="납기일"
                    valueFormat="YYYY-MM-DD"
                    {...form.getInputProps("deliveryDeadline")}
                />
                <Create.ButtonBox>
                    <Create.Button color="gray" onClick={closeModal}>취소</Create.Button>
                    <Create.Button
                        disabled={validateFormValues({
                            pageType: "purchaseOrder",
                            values: form.values,
                        })}
                        onClick={onSubmit}
                    >
                        수주 생성
                    </Create.Button>
                </Create.ButtonBox>
            </Create.Wrapper>
        </>
    )
};