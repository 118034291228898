import { mutateTransportation } from "@/api/transportation/useTransportationQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { PartnerAutoComplete } from "@/features/ui/autoComplete/partner/partner-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, Select, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { ScmApiTransportationPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "react-query";

const Create = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Select: FormSelect,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    Text: Text,
    Flex: Flex,
    DateInput: DateInput
});

interface TransportationCreateFormProps {
    orderId?: number;
}

export const TransportationCreateForm = (
    props: TransportationCreateFormProps
) => {
    const { orderId } = props;
    const { closeModal } = useModal();
    const queryClient = useQueryClient();
    const { mutate: createMutate } = useMutation(
        (params: ScmApiTransportationPostRequest) =>
            mutateTransportation.create(params).mutationFn(params as ScmApiTransportationPostRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('transportation');
            }
        }
    );

    const form = useForm({
        initialValues: {
            partnerCode : undefined,
            direction : undefined,
            status : undefined,
            scheduledAt : undefined,
            additional : undefined,
        }
    })

    const onSubmit = async () => {
        try {
            createMutate(
                {
                    transportationGetRequest : {
                        partnerCode : form.values.partnerCode!,
                        direction : form.values.direction!,
                        status : form.values.status!,
                        scheduledAt : form.values.scheduledAt!,
                        additional : form.values.additional!,
                    }
                },
                {
                    onSuccess: (res) => {
                        closeModal(form.values);
                        if (res.status === 200) {
                            customAlert(
                                "배차 등록에 성공하였습니다.",
                                "등록 성공",
                                "green"
                            )
                        }
                    },
                    onError: (error: any) => {
                        customAlert(handleErrorResponse({ error, errorContext: '배차 ' }), '배차 등록 실패', 'red')
                    }
                }
            )
        } catch (e) {
            customAlert(
                "배차 등록에 실패하였습니다.",
                "등록 실패",
                "red"
            )
        }
    };

    return (
        <>
            <Create.Wrapper>
                <PartnerAutoComplete
                    label="협력사"
                    placeholder="협력사"
                    {...form.getInputProps("partnerCode")}
                    withAsterisk
                />
                <Select 
                    label="방향"
                    placeholder="방향"
                    data={[
                        { label: "수주", value: "INBOUND" },
                        { label: "발주", value: "OUTBOUND" },
                    ]}
                    {...form.getInputProps("direction")}
                />
                <Select 
                    label="상태"
                    placeholder="상태"
                    data={[
                        { label: "예약확인중", value: "SCHEDULED" },
                        { label: "지연중", value: "LOADING" },
                        { label: "완료", value: "DONE" },
                        { label: "운행중", value: "ON_ROAD" },
                        { label: "사고", value: "ERROR" },
                    ]}
                    {...form.getInputProps("status")}
                />
                <Create.DateInput
                    popoverProps={{ withinPortal: true }}
                    icon={<IconCalendar size="1.2rem" />}
                    label="예정일"
                    valueFormat="YYYY-MM-DD"
                    {...form.getInputProps("scheduledAt")}
                />
                <Create.ButtonBox>
                    <Create.Button color="gray" onClick={closeModal}>취소</Create.Button>
                    <Create.Button
                        disabled={validateFormValues({
                            pageType: "transportation",
                            values: form.values,
                        })}
                        onClick={onSubmit}
                    >
                        배차 생성
                    </Create.Button>
                </Create.ButtonBox>
            </Create.Wrapper>
        </>
    )
};