import { itemType } from "@/constants/items";
import { AbstractItemAutoComplete } from "@/features/ui/autoComplete/abstractItem/abstractItem-autoComplete";
import { ItemCategoryAutoComplete } from "@/features/ui/autoComplete/itemCategory/itemCategory-autoComplete";
import styled from "@emotion/styled";
import {
  Checkbox,
  Group,
  Input,
  NumberInput,
  Select,
  Space,
  Text,
  Title,
} from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { ItemsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useTranslation } from "react-i18next";

export const ItemsForm = (params: {
  formData: UseFormReturnType<Partial<ItemsGet200ResponseRowsInner>>;
}) => {
  const { t } = useTranslation();
  const lnItemType = itemType.map((item: any) => ({
    ...item,
    label: t(item.label),
  }));
  // const lstItemType: string[] = Object.values(itemType);
  const { formData } = params;

  return (
    <ItemInfoWrapper>
      <ItemTitle>
        <InputField>
          <Title order={2}>*품명</Title>
          <Input
            placeholder="품목명"
            size="lg"
            {...formData.getInputProps("name")}
          />
        </InputField>
      </ItemTitle>
      <Spacing />
      <Field>
        <LabelText fw={700} c="dimmed">
          *타입
        </LabelText>
        <InputContent>
          <SelectInput
            placeholder="품목유형을 선택해 주세요."
            data={lnItemType}
            {...formData.getInputProps("itemType")}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          *코드
        </LabelText>
        <InputContent>
          <InputText
            placeholder="코드를 입력해주세요."
            {...formData.getInputProps("code")}
          />
          {/* error={true} */}
          {/* <WarningText>사용중인 품목코드입니다.</WarningText> */}
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          분류
        </LabelText>
        <InputContent>
          <ItemCategoryAutoComplete
            placeholder="품목분류를 선택해 주세요."
            {...formData.getInputProps("itemCategoryName")}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          품목군
        </LabelText>
        <InputContent>
          <AbstractItemAutoComplete
            placeholder="품목군을 선택해 주세요."
            {...formData.getInputProps("abstractItemCode")}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          규격
        </LabelText>
        <InputContent>
          <InputText
            placeholder="규격을 입력해주세요."
            {...formData.getInputProps("spec")}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          높이
        </LabelText>
        <InputContent>
          <NumberInput
            min={1}
            step={0.01}
            precision={2}
            placeholder="높이를 입력해주세요."
            {...formData.getInputProps("height")}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          깊이
        </LabelText>
        <InputContent>
          <NumberInput
            min={1}
            step={0.01}
            precision={2}
            placeholder="깊이를 입력해주세요."
            {...formData.getInputProps("depth")}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          폭
        </LabelText>
        <InputContent>
          <NumberInput
            min={1}
            step={0.01}
            precision={2}
            placeholder="폭 을 입력해주세요."
            {...formData.getInputProps("width")}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          재질
        </LabelText>
        <InputContent>
          <InputText
            placeholder="재질을 입력해주세요."
            {...formData.getInputProps("texture")}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          기준 UPH
        </LabelText>
        <InputContent>
          <InputText
            placeholder="기준 UPH를 입력해주세요."
            {...formData.getInputProps("standardUph")}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          단위
        </LabelText>
        <InputContent>
          <InputText
            placeholder="단위를 입력해주세요."
            {...formData.getInputProps("unitText")}
          />
        </InputContent>
      </Field>
      <Field>
        <LabelText fw={700} c="dimmed">
          LOT 관리 기준
        </LabelText>
        <Group>
          <Checkbox label="이름" {...formData.getInputProps("usesLotName")} />
          <Checkbox
            label="유효기한"
            {...formData.getInputProps("usesLotExpiration")}
          />
        </Group>
      </Field>
      <Spacing />
    </ItemInfoWrapper>
  );
};

const ItemInfoWrapper = styled.div`
  display: flex;
  padding: 16px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const ItemTitle = styled.div`
  display: flex;
  height: 108px;
  align-items: flex-start;
  align-self: stretch;
`;

const Spacing = styled(Space)`
  display: flex;
  height: 16px;
  flex-direction: column;
  align-items: flex-start;
`;

const InputField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  flex: 1 0 0;
`;

const InputContent = styled.div`
  display: flex;
  padding: 2px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
`;

const LabelText = styled<any>(Text)`
  display: flex;
  width: 120px;
  padding: 2px 0px;
  flex-direction: column;
  align-items: flex-start;
`;

const InputText = styled<any>(Input)`
  display: flex;
  width: 410px;

  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
`;

const SelectInput = styled<any>(Select)`
  display: flex;
  width: 200px;

  align-items: flex-start;
`;

const Field = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 23px;
  align-self: stretch;
`;

const WarningText = styled<any>(Text)`
  display: flex;
  padding: 10px 5px;
  color: var(--red-6, #fa5252);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 120% */
`;
