import { Box, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

export function UserForm(params: {
  code: string;
  onSubmit?: (values: any) => void;
}) {
  const { code, onSubmit } = params;
  const form = useForm({
    initialValues: {
      name : undefined,
      code: code,
      username : undefined,
      password : undefined,
    },
  });

  return (
    <Box maw={320} mx="auto">
      <form onSubmit={form.onSubmit((values) => onSubmit && onSubmit(values))}>
        <TextInput
          label="사용자 코드"
          withAsterisk
          {...form.getInputProps("code")}
        />
        <TextInput
          label="사용자 명"
          withAsterisk
          {...form.getInputProps("name")}
        />
        <TextInput
          label="로그인 아이디"
          withAsterisk
          {...form.getInputProps("username")}
        />
        <TextInput
          label="비밀번호"
          withAsterisk
          {...form.getInputProps("password")}
        />
        <Button type="submit">Submit</Button>
      </form>
    </Box>
  );
}
