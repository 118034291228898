import { useModal } from "@/features/modal/ModalStackManager"
import { OrderCreateForm } from "@/features/order/form/createForm"
import { OrderMailFrom } from "@/features/order/form/mailForm"
import { OrderItemsIncomingForm } from "@/features/order/form/orderItemsIncomingForm"
import { OrderStatementForm } from "@/features/order/form/statementForm"
import { OrderTable } from "@/features/order/table"
import { ActionButtonBox } from "@/features/standard/ActionButtonBox"
import { ActionHeader } from "@/features/standard/ActionHeader"
import { Header } from "@/features/standard/Header"
import { Main } from "@/features/standard/Main"
import { HeaderSubTitle } from "@/features/standard/SubTitle"
import { HeaderTitle } from "@/features/standard/Title"
import { Wrapper } from "@/features/standard/Wrapper"
import { usePub } from "@/hooks"
import { Button } from "@mantine/core"
import { ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models"
import { IconMail, IconPdf, IconPlus, IconTrash } from "@tabler/icons-react"
import { useState } from "react"

const Order = Object.assign({}, Main, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    Wrapper: Wrapper,
    ActionHeader: ActionHeader,
    ActionButton: Button,
    ActionButtonBox: ActionButtonBox,
    OrderTable: OrderTable,
    OrderCreateForm: OrderCreateForm,
})


export const OrderPage = () => {
    const publish = usePub();
    const { openModal } = useModal();
    const [isRowBtnActive, setIsRowBtnActive] = useState<boolean>(false);
    const [selectedRowsInform, setSelectedRowsInform] = useState<ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[]>([]);
    const [data, setData] = useState<any>([]);
    
    const handleRowSelect = (
        selectedRows: any,
        formatterPropsData: any,
    ) => {
        setIsRowBtnActive(selectedRows.size === 1);
        setData(formatterPropsData);
        setSelectedRowsInform(selectedRowsInform);
    }
    return (
        <>
            <Order.Wrapper>
                <Order.ActionHeader>
                    <Order.ActionButtonBox>
                        <Order.ActionButton
                            leftIcon={<IconPlus />}
                            onClick={() => openModal(
                                <Order.OrderCreateForm />,
                                null,
                                "발주 추가",
                            )}
                        >
                            추가
                        </Order.ActionButton>
                        <Order.ActionButton
                            leftIcon={<IconPlus />}
                            color="orange"
                            disabled={!isRowBtnActive}
                            onClick={() => openModal(
                                <OrderItemsIncomingForm formatterProps={data} />,
                                null,
                                "입고",
                            )}
                        >
                            입고
                        </Order.ActionButton>
                        <Order.ActionButton
                            leftIcon={<IconMail />}
                            color="green"
                            onClick={() => openModal(
                                <OrderMailFrom />,
                                null,
                                "발주서 메일 전송",
                                true
                            )}
                        >
                            발주서 메일 전송
                        </Order.ActionButton>
                        <Order.ActionButton
                            leftIcon={<IconPdf />}
                            color="orange"
                            onClick={() => openModal(
                                <OrderStatementForm data={data} />,
                                null,
                                ""
                            )}
                            disabled={!isRowBtnActive}
                        >
                            발주서 PDF 인쇄
                        </Order.ActionButton>
                    </Order.ActionButtonBox>
                    <Order.ActionButton
                        color="red"
                        rightIcon={<IconTrash />}
                        onClick={() => publish("deleteSelectedItems")}
                    >
                        선택된 행 삭제
                    </Order.ActionButton>
                </Order.ActionHeader>
                <Order.Header>
                    <Order.HeaderTitle>발주</Order.HeaderTitle>
                    <Order.HeaderSubTitle>
                        공급업체에 주문한 내역
                    </Order.HeaderSubTitle>
                </Order.Header>
                <Order.OrderTable onRowSelect={handleRowSelect} />
            </Order.Wrapper>
        </>
    )
}