import { itemTypeColor } from "@/constants/items";
import { AbstractItemDetailForm } from "@/features/abstractItem/form/detailForm";
import { ItemCategoryDetailForm } from "@/features/itemCategory/form/DetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Badge, Checkbox, Space } from "@mantine/core";
import { ItemsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useTranslation } from "react-i18next";

const DetailItem = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
  Checkbox: Checkbox
});

export const Header = (params: {
  itemData: Partial<ItemsGet200ResponseRowsInner>;
}) => {
  const {
    name,
    code,
    itemType,
    itemCategoryName,
    abstractItemCode,
    usesLotName,
    usesLotExpiration,
    spec,
    height,
    standardUph,
    depth,
    width,
    texture,
    unitText,
  } = params.itemData;

  const { t } = useTranslation();

  const { openModal } = useModal();

  //name, code, itemType, itemCategoryId, abstractItemId
  // TODO : 추후 props로 받는값으로 바꿔주기
  return (
    <Container>
      <DetailItem.Title>{`[품목 상세]`}</DetailItem.Title>
      <Space h="md" />
      <DetailItem.Field>
        <DetailItem.Label>품목명</DetailItem.Label>
        <DetailItem.Content>{name}</DetailItem.Content>
      </DetailItem.Field>
      <DetailItem.Field>
        <DetailItem.Label>품목코드</DetailItem.Label>
        <DetailItem.Content>{code}</DetailItem.Content>
      </DetailItem.Field>
      <DetailItem.Field>
        <DetailItem.Label>품목유형</DetailItem.Label>
        <DetailItem.Content><Badge color={itemTypeColor[itemType!]}>{t(itemType!)}</Badge></DetailItem.Content>
      </DetailItem.Field>
      <DetailItem.Field>
        <DetailItem.Label>품목분류</DetailItem.Label>
        <DetailItem.Link
          onClick={() => openModal(
            <ItemCategoryDetailForm ItemCategoryName={itemCategoryName} />,
            null,
            ""
          )}
        >
          {itemCategoryName}
        </DetailItem.Link>
      </DetailItem.Field>
      <DetailItem.Field>
        <DetailItem.Label>품목군</DetailItem.Label>
        <DetailItem.Link
          onClick={() => openModal(
            <AbstractItemDetailForm AbstractItemCode={abstractItemCode} />,
            null,
            ""
          )}
        >
          {abstractItemCode}
        </DetailItem.Link>
      </DetailItem.Field>
      <DetailItem.Field>
        <DetailItem.Label>규격</DetailItem.Label>
        <DetailItem.Content>{spec}</DetailItem.Content>
      </DetailItem.Field>
      <DetailItem.Field>
        <DetailItem.Label>높이</DetailItem.Label>
        <DetailItem.Content>{height}</DetailItem.Content>
      </DetailItem.Field>
      <DetailItem.Field>
        <DetailItem.Label>깊이</DetailItem.Label>
        <DetailItem.Content>{depth}</DetailItem.Content>
      </DetailItem.Field>
      <DetailItem.Field>
        <DetailItem.Label>폭</DetailItem.Label>
        <DetailItem.Content>{width}</DetailItem.Content>
      </DetailItem.Field>
      <DetailItem.Field>
        <DetailItem.Label>재질</DetailItem.Label>
        <DetailItem.Content>{texture}</DetailItem.Content>
      </DetailItem.Field>
      <DetailItem.Field>
        <DetailItem.Label>기준UPH</DetailItem.Label>
        <DetailItem.Content>{standardUph}</DetailItem.Content>
      </DetailItem.Field>
      <DetailItem.Field>
        <DetailItem.Label>단위</DetailItem.Label>
        <DetailItem.Content>{unitText}</DetailItem.Content>
      </DetailItem.Field>
      <DetailItem.Field>
        <DetailItem.Label>재고 로트 관리 방법</DetailItem.Label>
        <Content>
          <DetailItem.Checkbox
            size="xs"
            mr={12}
            label="이름"
            defaultChecked={usesLotName}
            disabled
          />
          <DetailItem.Checkbox
            size="xs"
            label="유효기한"
            defaultChecked={usesLotExpiration}
            disabled
          />
        </Content>
      </DetailItem.Field>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 16px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  background: ${theme.colors?.gray?.[0]};
`;

const Content = styled.span`
  display: flex;
  padding: 2px 0px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`;
