import { BadgeBox } from "@/features/standard/badge/BadgeBox"
import { BadgeContainer } from "@/features/standard/badge/BadgeContainer"
import { BadgeIcon } from "@/features/standard/badge/BadgeIcon"
import { BadgeMain } from "@/features/standard/badge/BadgeMain"
import { BadgeQuantity } from "@/features/standard/badge/BadgeQuantity"
import { BadgeSmallBox } from "@/features/standard/badge/BadgeSmallBox"
import { BadgeTextBox } from "@/features/standard/badge/BadgeTextBox"
import { theme } from "@/styles/theme"
import { Text } from "@mantine/core"
import { IconBuildingWarehouse } from "@tabler/icons-react"

const Badge = Object.assign(BadgeMain, {
    Box: BadgeBox,
    SmallBox: BadgeSmallBox,
    Quantity: BadgeQuantity,
    Icon: BadgeIcon,
    Text: Text,
    TextBox: BadgeTextBox,
    Container: BadgeContainer,
})
export const PurchaseOrderDetailDataHeader = () => {
    return (
        <Badge.Container>
            <Badge.Box>
                <Badge.SmallBox>
                    <Badge.Icon>
                        <IconBuildingWarehouse size={26} color={theme.colors?.indigo?.[5]} />
                    </Badge.Icon>
                    <Badge.TextBox>
                        <Badge.Quantity fz="lg" color={theme.colors?.indigo?.[5]}>
                            1 개
                        </Badge.Quantity>
                        <Badge.Text fz="xs" color={theme.colors?.gray?.[6]}>
                            금형 재고 갯수
                        </Badge.Text>
                    </Badge.TextBox>
                </Badge.SmallBox>
                <Badge.SmallBox>
                    <Badge.Icon>
                        <IconBuildingWarehouse size={26} color={theme.colors?.indigo?.[5]} />
                    </Badge.Icon>
                    <Badge.TextBox>
                        <Badge.Quantity fz="lg" color={theme.colors?.indigo?.[5]}>
                            개
                        </Badge.Quantity>
                        <Badge.Text fz="xs" color={theme.colors?.gray?.[6]}>
                            작업지시
                        </Badge.Text>
                    </Badge.TextBox>
                </Badge.SmallBox>
            </Badge.Box>
        </Badge.Container>
    )
}