import { mutateEquipment } from "@/api/equipment/useEquipmentQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { SitesAutoComplete } from "@/features/ui/autoComplete/site/sites-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiEquipmentsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "react-query";

export const EquipmentCreateForm = () => {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();

  const { mutate: postMutate } = useMutation(
    (params: MasterApiEquipmentsPostRequest) =>
      mutateEquipment.create(params).mutationFn(params as MasterApiEquipmentsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('equipment');
      },
    }
  );

  const form = useForm({
    initialValues: {
      siteCode: undefined,
      code: undefined,
      name: undefined,
      equipmentType: undefined,
      volt : undefined,
      seq: undefined,
      toLocationCode: undefined,
      targetLocationCode: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      postMutate(
        {
          equipmentsGetRequest: {
            siteCode: form.values.siteCode!,
            code: form.values.code!,
            name: form.values.name!,
            equipmentType: form.values.equipmentType!,
            volt: form.values.volt!,
            seq: form.values.seq!,
            toLocationCode: form.values.toLocationCode!,
            targetLocationCode: form.values.targetLocationCode!
          }
        },
        {
          onSuccess: (res) => {
            closeModal(form.values);
            if (res.status === 200) {
              customAlert(
                "설비 생성에 성공하였습니다.",
                "생성 성공",
                "green"
              )
            } else {
              customAlert(
                "설비 생성에 실패하였습니다.",
                "생성 실패",
                "red"
              )
            }
          },
          onError: (error: any) => {
            customAlert(handleErrorResponse({ error, errorContext: '설비코드' }), '설비 생성 실패', 'red')
          }
        }
      );
    } catch (e) {
      customAlert(
        "설비 생성에 실패하였습니다.",
        "생성 실패",
        "red"
      )
    }
  };

  return (
    <Box w={320} mx="auto">
      <Form>
        <ModalFieldSet>
          <SitesAutoComplete
            withAsterisk
            label="사업장코드"
            width={"100%"}
            {...form.getInputProps("siteCode")}
          />
          <TextInput
            withAsterisk
            label="설비코드"
            {...form.getInputProps("code")}
          />
          <TextInput withAsterisk label="설비명" {...form.getInputProps("name")} />

          <TextInput
            label="설비유형"
            {...form.getInputProps("equipmentType")}
          />
          <TextInput
            label="설비전압"
            {...form.getInputProps("volt")}
          />
          <LocationAutoComplete
            label="입고창고"
            {...form.getInputProps("toLocationCode")}
          />

          <LocationAutoComplete
            label="품목 생산 창고"
            {...form.getInputProps("targetLocationCode")}
          />
          <TextInput
            type="number"
            label="시퀀스(순서)"
            {...form.getInputProps("seq")}
          />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              취소
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "equipment",
                values: form.values,
              })}
              onClick={onSubmit}
            >
              설비 생성
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
};

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
