import { mutatePurchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import { Main } from "@/features/standard/Main";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Select, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { PurchaseOrdersGet200ResponseRowsInner, ScmApiPurchaseOrdersPurchaseOrderIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "react-query";

const Update = Object.assign({}, Main, {
    Button: Button,
    Input: TextInput,
    Select: FormSelect,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    DateInput: DateInput
});

interface OrderStatusFormProps {
    formatterProps: PurchaseOrdersGet200ResponseRowsInner | undefined;
}

export const OrderStatusForm = (params: OrderStatusFormProps) => {
    const { formatterProps } = params;
    const queryClient = useQueryClient();
    const { mutate: updateMutate } = useMutation(
        (params: ScmApiPurchaseOrdersPurchaseOrderIdPutRequest) =>
            mutatePurchaseOrders.update(params).mutationFn(params as ScmApiPurchaseOrdersPurchaseOrderIdPutRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('purchaseOrders');
            }
        }
    );

    const { closeModal } = useModal();

    const form = useForm({
        initialValues: {
            partnerCode: formatterProps?.partnerCode,
            status: formatterProps?.status,
            code : formatterProps?.code,
        }
    });

    const onSubmit = async () => {
        try {
            updateMutate({
                purchaseOrderId: formatterProps?.id ?? 0,
                purchaseOrdersGetRequest: {
                    direction: "OUTBOUND",
                    code: form.values.code!,
                    partnerCode: form.values.partnerCode!,
                    status: form.values.status!,
                }
            }, {
                onSuccess: (res) => {
                    closeModal(formatterProps);
                    if (res.status === 200) {
                        customAlert(
                            "발주 상태가 성공적으로 수정되었습니다.",
                            "발주 상태 수정 성공",
                            "green",
                        )
                    }
                },
                onError: (error: any) => {
                    customAlert(handleErrorResponse({ error, errorContext: '발주 상태' }), '발주 상태 수정 실패', 'red')
                }
            });
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <>
            <Update.Wrapper>
                <Select
                    label="발주상태"
                    placeholder="발주상태"
                    data={[
                        { value: "DRAFT", label: "대기" },
                        { value: "IN_PROGRESS", label: "진행중" },
                        { value: "DONE", label: "완료" },
                        { value: "CANCELED", label: "취소됨" },
                    ]}
                    defaultValue={formatterProps?.status}
                    {...form.getInputProps("status")}
                    error={form.isDirty("status") ? "저장 필요" : ""}
                    styles={{
                        input: {
                            color: form.isDirty("status") ? "cadetblue !important" : "inherit",
                            borderColor: form.isDirty("status") ? "cadetblue !important" : "inherit",
                        },
                        error: { fontSize: 11, color: "cadetblue" }
                    }}
                />
                <Update.ButtonBox>
                    <Update.Button color="gray" onClick={closeModal}>취소</Update.Button>
                    <Update.Button
                        disabled={validateFormValues({
                            pageType: "purchaseOrder",
                            values: form.values,
                        })}
                        onClick={onSubmit}
                    >
                        발주 상태 수정
                    </Update.Button>
                </Update.ButtonBox>
            </Update.Wrapper>
        </>
    )
};